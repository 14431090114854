export function capitalizeFirstLetter(string: any) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const toKebabCase = (string: any) =>
  string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

export const toSentence = (arr: any, oxfordComma = true) => {
  return arr.length > 1
    ? arr.slice(0, arr.length - 1).join(', ') +
        `${oxfordComma ? ',' : ''} and ` +
        arr.slice(-1)
    : arr[0];
};

export const toCamelCase = (s: any) => {
  return s.replace(/([-_][a-z])/gi, ($1: any) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const trim = (s: string) => s.trim();
